<template>
  <div
    id="uploadDrag"
    ref="openUploadLayer"
    v-clickoutside:[openlayer]="handleClose"
  >
    <a-drawer
      class="my-drawer"
      title="上传列表"
      width="600"
      placement="right"
      :mask="false"
      :visible="value"
      :after-visible-change="afterVisibleChange"
      @close="handleClose(false)"
      :get-container="() => openUploadLayer"
    >
      <div class="tips-txt flex flex-j-c-b align-c">
        <span>有{{ len }}个文件上传成功</span>
        <a-tooltip
          placement="left"
          overlayClassName="my-tooltip"
          title="严禁利用本网站上传、传播暴力恐怖、色情违法及侵犯他人合法权益的违法信息，一经发现将严格按照相关法律法规处理。"
          :get-popup-container="
            trigger => {
              return trigger.parentElement
            }
          "
        >
          <ExclamationCircleOutlined />
        </a-tooltip>
      </div>
      <div class="list-box">
        <div class="list" v-for="(item, index) in list" :key="index">
          <div class="flex flex-j-c-b align-c">
            <div class="flex-1 flex flex-j-c-b align-c">
              <div class="file-name">
                <img class="icon" :src="OSS_ICON[item.file_type]?.url" alt="" />
                <span>{{ item.file_name }}</span>
              </div>
              <span>{{ item.file_size }}</span>
            </div>
            <CheckCircleOutlined class="green-c" v-show="item.upStatus === 2" />
            <CloseCircleOutlined class="red-c" v-show="item.upStatus === 3" />
          </div>
          <a-progress :percent="item.point" size="small" :show-info="false" />
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import { OSS_ICON } from '@/common/hooks/oss-config'
import { clickoutside } from '@/common/hooks'
export default defineComponent({
  components: {
    ExclamationCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
  },
  props: {
    value: Boolean,
    list: Array,
    len: Number,
  },
  setup(props) {
    const store = useStore()
    const afterVisibleChange = visible => {
      console.log(visible)
    }
    const handleClose = (status = false) => {
      store.commit('setUploadStatus', status)
    }
    const openlayer = ['clickOpenUploadLayer', 'uploadDrag']
    const openUploadLayer = ref()
    return {
      afterVisibleChange,
      handleClose,
      OSS_ICON,
      openlayer,
      openUploadLayer,
    }
  },
  directives: {
    clickoutside,
  },
})
</script>

<style lang="scss">
.my-drawer {
  &.ant-drawer-right .ant-drawer-content-wrapper {
    bottom: 0 !important;
    top: auto !important;
    height: 500px;
  }
  .ant-drawer-body {
    padding: 0;
  }
}
.my-tooltip {
  .ant-tooltip-inner {
    background-color: #fff;
    color: #555;
    border: 1px solid #ddd;
    font-size: 12px;
  }
  .ant-tooltip-arrow-content {
    background-color: #ddd;
  }
}
</style>
<style lang="scss" scoped>
.tips-txt {
  padding: 8px 20px 8px 24px;
  line-height: 20px;
  background-color: #347bff;
  color: #fff;
}
.list {
  padding: 8px 15px 8px 25px;
  border-bottom: 1px solid #eee;
}
.flex-1 {
  flex: 0 0 400px;
}
.icon {
  width: 27px;
  height: 32px;
  margin-right: 10px;
}
.list-box {
  height: 409px;
  overflow-y: auto;
}
.file-name {
  width: 320px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>