<template>
  <div class="flex container">
    <!-- 左边导航栏 -->
    <div class="nav-left">
      <img class="mt-24" :src="require('@/assets/images/logo2.png')" alt="" />
      <div class="mt-30 mb-50">
        <a-badge class="txt-c">
          <a-dropdown>
            <a-avatar :src="OSSURL + '/' + store.state.userInfo.avatar_url">
              <template #icon><UserOutlined /></template>
            </a-avatar>
            <template #overlay>
              <a-menu>
                <a-menu-item>
                  <a href="javascript:;" @click.prevent="handleGoPerson"
                    >个人中心</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click.prevent="handleGoOut"
                    >退出登录</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <p class="login-name">{{ store.state.userInfo.account }}</p>
        </a-badge>
      </div>
      <ul class="nav-list">
        <li
          class="flex flex-d flex-align-center"
          v-for="item in navList"
          :key="item.id"
          @click="handleRouter(item.href)"
          :class="{ active: item.href == routerName }"
        >
          <div class="flex flex-d flex-align-center">
            <i class="iconfont" :class="item.icon"></i>
            <p>{{ item.title }}</p>
          </div>
          <div :class="{ activeBox: item.href == routerName }"></div>
        </li>
      </ul>
    </div>
    <router-view></router-view>
  </div>

  <div class="upload-list-icon clickOpenUploadLayer" v-show="!isUploadStatus">
    <span @click="handleDrawer"
      ><i class="iconfont icon-shangxiazuoyouTriangle14"></i
    ></span>
  </div>

  <uploadList
    v-model:value="isUploadStatus"
    :list="storeList"
    :len="upLoadLen"
  ></uploadList>
</template>

<script>
import { UserOutlined } from '@ant-design/icons-vue'
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { fetchSiteMenu } from '@/api/user/index.js'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import NProgress from 'nprogress'
import { message } from 'ant-design-vue'
import uploadList from '@/common/components/uploadList'
import { OSSURL } from "@/common/hooks/new_config"
export default defineComponent({
  components: {
    UserOutlined,
    uploadList,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const navList = computed(() => store.state.navList)
    const routerName = ref()
    const isUploadStatus = computed(() => store.state.isUploadStatus)
    const storeList = computed(() => store.state.storeUploadList)
    const upLoadLen = computed(() => store.getters.upLoadLen)
    const handleGoPerson = () => {
      routerName.value = 'personInfo'
      router.push({name: 'personInfo'})
      store.commit('saveRouterName', 'personInfo')
    }
    const handleDrawer = () => {
      store.commit('setUploadStatus', true)
    }
    // 特殊处理不展示的导航，后期删掉
    const resetNav = res => {
      const _ids = [88]
      return res.filter(item => !_ids.includes(item.id))
    }
    // 获取导航
    const getSiteMenu = () => {
      fetchSiteMenu({
        // client: 'arseek.web',
      }).then(res => {
        store.commit('setNavList', resetNav(res.data))
      })
    }
    // 路由跳转
    const handleRouter = name => {
      routerName.value = name
      store.commit('saveRouterName', name)
      router.replace({ name })
    }
    // 退出登录
    const handleGoOut = () => {
      store.dispatch('goOut')
      store.commit('saveRouterName', 'login')
    }
    // watch(() => route.name, (oldName,newName)=>{
    //   console.log(oldName, 'oldName')
    //   console.log(newName, 'newName')
    // })
    onMounted(() => {
      routerName.value = route.name
      if (navList.value && navList.value.length > 0) return
      getSiteMenu()
    })
    return {
      navList,
      handleRouter,
      routerName,
      handleGoOut,
      store,
      isUploadStatus,
      handleDrawer,
      storeList,
      upLoadLen,
      handleGoPerson,
      OSSURL
    }
  },
})
</script>

<style lang="scss" scoped>
.upload-list-icon {
  position: fixed;
  right: 0;
  top: 50%;
  color: #fff;
  width: 22px;
  height: 24px;
  z-index: 99;
  span {
    display: block;
    padding: 5px;
    cursor: pointer;
    background: #347bff;
    transition: all 0.2s;
    &:hover {
      background: rgba($color: #347bff, $alpha: 0.8);
    }
  }
  i {
    font-size: 12px;
  }
}

.nav-left {
  display: flex;
  flex-direction: column;
  width: 110px;
  align-items: center;
  background-color: #3d445e;
  height: 100vh;
  color: #8ba8ba;
  overflow-y: auto;
}
.nav-list {
  width: 100%;
  li {
    margin-bottom: 15px;
    cursor: pointer;
    user-select: none;
    position: relative;
  }
  .iconfont {
    font-size: 30px;
    & + p {
      margin: 10px 0;
    }
  }
  .active {
    color: #fff;
  }
}
.container {
  height: 100vh;
  overflow: hidden;
}
.activeBox {
  width: 0;
  height: 0;
  border-width: 12px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
  position: absolute;
  top: 50%;
  right: 0;
  transform: rotate(90deg);
  margin-top: -20px;
}
.login-name {
  color: rgb(139, 168, 186);
  margin: 10px 0 0;
  text-align: center;
  word-break: break-all;
  line-height: 20px;
}
@media screen and (max-width: 1300px) {
  .activeBox {
    display: none;
  }
}
</style>